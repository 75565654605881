import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'auth2',
    loadChildren: () => import('./pages/auth2/auth2.module').then( m => m.Auth2PageModule)
  },
  {
    path: 'huella',
    loadChildren: () => import('./pages/huella/huella.module').then( m => m.HuellaPageModule)
  },
  {
    path: 'recibo-nomina',
    loadChildren: () => import('./pages/recibo-nomina/recibo-nomina.module').then( m => m.ReciboNominaPageModule)
  },
  {
    path: 'fondo-ahorro',
    loadChildren: () => import('./pages/fondo-ahorro/fondo-ahorro.module').then( m => m.FondoAhorroPageModule)
  },
  {
    path: 'incetivos-acumulados',
    loadChildren: () => import('./pages/incetivos-acumulados/incetivos-acumulados.module').then( m => m.IncetivosAcumuladosPageModule)
  },
  {
    path: 'declaranet',
    loadChildren: () => import('./pages/declaranet/declaranet.module').then( m => m.DeclaranetPageModule)
  },
  {
    path: 'vacaciones',
    loadChildren: () => import('./pages/vacaciones/vacaciones.module').then( m => m.VacacionesPageModule)
  },
  {
    path: 'perfil',
    loadChildren: () => import('./pages/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'lentes',
    loadChildren: () => import('./pages/lentes/lentes.module').then( m => m.LentesPageModule)
  },
  {
    path: 'empleados',
    loadChildren: () => import('./pages/empleados/empleados.module').then( m => m.EmpleadosPageModule)
  },
  {
    path: 'energia',
    loadChildren: () => import('./pages/energia/energia.module').then( m => m.EnergiaPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
