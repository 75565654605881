import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Nómina', url: '/recibo-nomina', icon: 'wallet' },
    { title: 'Fondo Ahorro', url: '/fondo-ahorro', icon: 'archive' },
    { title: 'Puntualidad', url: '/incetivos-acumulados', icon: 'alarm' },
    { title: 'Declaranet', url: '/declaranet', icon: 'albums' },
    { title: 'Vacaciones', url: '/vacaciones', icon: 'bicycle' },
    { title: 'Prestación Lentes', url: '/lentes', icon: 'glasses' },
    { title: 'Prestación Energía', url: '/energia', icon: 'flash' },
    { title: 'Mi Perfil', url: '/perfil', icon: 'accessibility' },
    { title: 'Terminar Sesión', url: '/login', icon: 'log-out' },
  ];
  public labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
  constructor() {}
}
